import { useCallback, useEffect } from "react";

import { useSite } from "~/contexts";
import { useLoadScript } from "~/lib/utils/load-script";

export const useInsider = () => {
  const setInsiderPageType = useCallback(
    (
      pageType:
        | "Home"
        | "Product"
        | "Category"
        | "Search"
        | "Basket"
        | "Checkout"
        | "Confirmation"
        | "Content",
    ) => {
      window.insider_object = window.insider_object || {};
      window.insider_object.page = {
        type: pageType,
      };
    },
    [],
  );

  return {
    setInsiderPageType,
  };
};

export const Insider = () => {
  const { site } = useSite();

  useEffect(() => {
    window.insider_object = window.insider_object || {};
  }, []);

  useLoadScript(
    `https://${site.insiderPartnerName}.api.useinsider.com/ins.js?id=${site.insiderPartnerId}`,
  );

  return <></>;
};
